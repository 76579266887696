import "@hotwired/turbo-rails";

// NOTE: Include jquery-ujs for Rails-specific jquery behaviors, to include
// automatically including CSRF tokens in XHR requests:
import "bootstrap";
import "jquery-ujs";

import Popover from "../behaviors/popover";
import BehaviorsConnector from "../utilities/behaviors_connector";

const connector = new BehaviorsConnector(Popover);

connector.connect();
